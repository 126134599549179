<template>
  <Popup ref="popupRef" width="500px" title="审核驳回" confirm-text="提交" @confirm="confirm" @close="resetForm">
    <el-input
      type="textarea"
      :rows="8"
      :maxlength="150"
      show-word-limit
      placeholder="请输入驳回理由"
      v-model="inputValue">
    </el-input>
  </Popup>
</template>

<script>
import { auditOrderApi } from '@/api/admin.js'
import Popup from '@/components/Popup'
export default {
  components: {
    Popup
  },
  data() {
    return {
      cid: '',
      inputValue: ''
    }
  },
  methods: {
    open(option) {
      if(!option.cid) return
      this.cid = option.cid
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.inputValue = ''
      this.cid = ''
    },
    confirm() {
      if(!this.inputValue.trim()) return this.$tips({message: '请输入驳回理由', type: 'warning'})
      const formData = {
        status: 2,
        auditReason: this.inputValue.trim(),
        cid: this.cid
      }
      console.log('formData', formData)
      auditOrderApi(formData).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>